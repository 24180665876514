import React, { useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Html } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';
import glbModel from './final.glb'; // Replace with your GLB file path

function Model() {
  const [error, setError] = useState(null);
  const modelRef = useRef();

  // Load the GLB model using GLTFLoader
  const gltf = useLoader(GLTFLoader, glbModel, (loader) => {
    loader.load(
      glbModel,
      (loadedGltf) => {
        console.log('Model loaded:', loadedGltf);

        // Traverse the scene to ensure all meshes are visible and not transparent
        loadedGltf.scene.traverse((child) => {
          if (child.isMesh) {
            child.material.transparent = false; // Ensure not transparent
            child.material.opacity = 1; // Set full opacity
            child.material.wireframe = false; // Ensure wireframe mode is off
          }
        });
      },
      undefined,
      (err) => {
        console.error('Error loading model:', err);
        setError(err);
      }
    );
  });

  // Update the model matrix in each frame
  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.updateMatrixWorld(true);
    }
  });

  if (error) {
    return <Html center><div style={{ color: 'red' }}>Error loading model</div></Html>;
  }

  if (!gltf) {
    return <Html center><div>Loading...</div></Html>;
  }

  // Return the loaded model, applying scale and rotation
  return (
    <primitive
      ref={modelRef}
      object={gltf.scene}
      scale={0.5} // Adjust scale to make the model visible
      position={[0, 0, 0]} // Center the model
      rotation={[0, Math.PI / 2, 0]} // Adjust rotation if needed
      dispose={null}
    />
  );
}

function ThreeScene() {
  return (
    <Canvas
      style={{ width: '800px', height: '600px' }} // Increase canvas size if needed
      camera={{ position: [5, 5, 5], fov: 50 }}> {/* Adjust camera */}
      <ambientLight intensity={1} /> {/* Stronger ambient light */}
      <directionalLight position={[10, 10, 10]} intensity={2} /> {/* Stronger directional light */}
      {/* No helpers added to Canvas */}
      <Model />
      <OrbitControls />
    </Canvas>
  );
}

export default ThreeScene;
